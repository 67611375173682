module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"the ICONICO","short_name":"ICONICO","lang":"en","description":"The world’s most extraordinary homes are too important not to be shared","start_url":"/?source=pwa'","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"./src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a2d5641742ae2f972cf0e086a8137da3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
